import React, { useRef } from "react"
import { CircleSection } from "../sections"
import FloatingCircle, { CirclesContainer } from "../FloatingCircle"

const Circles = ({ isLarge, isIntersecting }) => {
  const ref = useRef()
  return (
    <CircleSection bg="pinklightest" ref={ref}>
      {isIntersecting ? (
        <CirclesContainer>
          <FloatingCircle
            sectionRef={ref}
            depth={1}
            radius={376}
            align={["right", "top"]}
            position={[-189, 56]}
            fill="#FDF2F5"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={1.3}
            radius={427}
            align={["left", "bottom"]}
            position={[-60, 22]}
            fill="#FDF2F5"
          />
        </CirclesContainer>
      ) : (
        ""
      )}
      <p className="druzimo-se" style={isLarge ? { left: "5rem" } : {}}>
        <strong>Moj opuštajući prostor</strong> gdje je lijepo, toplo, mirisno i
        ugodno.
      </p>
      <p className="druzimo-se" style={isLarge ? { right: "5rem" } : {}}>
        <strong>Vaš dom.</strong> U Vašem toplom, domaćem okruženju razgovarat
        ćemo o svemu što Vas zanima, upoznati prostor i pripremiti se za porod
        kada on i krene. Pridružiti nam se može i partner, a volimo kada su s
        nama i ostali članovi obitelji.
      </p>
    </CircleSection>
  )
}

export default Circles
