import React, { useRef } from "react"
import { CircleSection } from "../sections"
import FloatingCircle, { CirclesContainer } from "../FloatingCircle"

const Circles = ({ isIntersecting }) => {
  const ref = useRef()
  return (
    <CircleSection bg="pinktouch" ref={ref}>
      {isIntersecting ? (
        <CirclesContainer>
          <FloatingCircle
            sectionRef={ref}
            depth={1.2}
            radius={404}
            align={["right", "top"]}
            position={[759, 158]}
            fill="#ffffff"
          />
        </CirclesContainer>
      ) : (
        ""
      )}
      <p>
        Cjelovitu obuku za porođajnu doulu prošla sam kod{" "}
        <b>Reke Deszi ispred svjetske udruge 'Dona International'</b> u Zagrebu.
        Cjelovitu obuku za postporođajnu doulu završila sam kroz edukaciju{" "}
        <b>Balkan Assosiation of Birth Workers.</b> Edukaciju sam nastavila kroz
        kongrese, simpozije, radionice i svakodnevnu praksu.{" "}
        <b>Postala sam i doula mentor svojim kolegicama,</b> na što sam posebno
        ponosna.
      </p>
    </CircleSection>
  )
}

export default Circles
