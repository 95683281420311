import React, { useRef } from 'react'
import { CircleSection } from '../sections'
import FloatingCircle, { CirclesContainer } from '../FloatingCircle'

const Circles = ({ isLarge, isIntersecting }) => {
  const ref = useRef()
  return (
    <CircleSection bg="pinktouch" ref={ref}>
      {isIntersecting ? (
        <CirclesContainer>
          <FloatingCircle
            sectionRef={ref}
            depth={1}
            radius={404}
            align={['left', 'top']}
            position={[29, 56]}
            fill="#FDF2F5"
          />{' '}
          <FloatingCircle
            sectionRef={ref}
            depth={2}
            radius={180}
            align={['right', 'top']}
            position={[39, 92]}
            fill="#ffffff"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={3}
            radius={99}
            align={['right', 'bottom']}
            position={[140, 61]}
            fill="#FCDEE9"
          />
        </CirclesContainer>
      ) : (
        ''
      )}
      <p className="druzimo-se" style={isLarge ? { right: '5rem' } : {}}>
        <strong>
          Mala kuća moj je dom u kojem rado ugošćujem svoje trudnice.
        </strong>{' '}
        Ondje puno razgovaramo, isprobavamo položaje za porod, vježbamo disanje,
        učimo koristiti loptu i rekvizite pogodne da nam olakšaju porod. Naša
        Mala kuća udobno je, toplo i mirno mjesto samo za nas.
      </p>
      <p className="druzimo-se" style={isLarge ? { left: '5rem' } : {}}>
        <strong>Naše čarobno mjesto u prirodi.</strong> Popit ćemo zdravi sok i
        krenuti u šetnju tempom koji mami odgovara. Sa sobom ćemo ponijeti vodu,
        dekicu i dobro raspoloženje. To će biti vrijeme samo za nas.
      </p>
    </CircleSection>
  )
}

export default Circles
