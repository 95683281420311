import React, { useRef, useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useMedia from "use-media"
import { CircleSection } from "../sections"
import FloatingCircle, { CirclesContainer } from "../FloatingCircle"
import Button from "../Button"
import VerticalCard, { VerticalCardsContainer } from "../VerticalCard"
import DrPrka from "../DrPrka"

const Suradnici = ({ isIntersecting }) => {
  const data = useStaticQuery(graphql`
    query {
      danijelaLovincic: file(
        relativePath: { eq: "associates/danijela_lovincic.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ivaBrcic: file(relativePath: { eq: "associates/iva_brcic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ivaKonjevod: file(relativePath: { eq: "associates/iva_konjevod.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      andreaVidas: file(relativePath: { eq: "associates/andrea_vidas.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jelenaSkender: file(
        relativePath: { eq: "associates/jelena_skender.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doraSegovic: file(relativePath: { eq: "associates/dora_segovic.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      majaDzapo: file(relativePath: { eq: "associates/maja_dzapo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      matijaPrka: file(relativePath: { eq: "associates/matija_prka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sandraKrenBalen: file(
        relativePath: { eq: "associates/sandra_kren_balen.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vedranaDuckic: file(
        relativePath: { eq: "associates/vedrana_duckic.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [isDrPrkaOpen, setIsDrPrkaOpen] = useState(false)
  const ref = useRef()

  const newTabLink = useCallback(url => {
    window.open(url, "_blank")
  }, [])

  const openDrPrka = useCallback(() => setIsDrPrkaOpen(true), [])
  const closeDrPrka = useCallback(() => setIsDrPrkaOpen(false), [])

  const notLargestScreen = useMedia({ maxWidth: 1100 })

  return (
    <CircleSection bg="olivelightest" ref={ref} noSmallPadding>
      {isIntersecting ? (
        <CirclesContainer>
          {notLargestScreen ? (
            ""
          ) : (
            <FloatingCircle
              sectionRef={ref}
              depth={1}
              radius={647}
              align={["left", "top"]}
              position={[-69, 520]}
              fill="#F3F3EF"
            />
          )}
          <FloatingCircle
            sectionRef={ref}
            depth={1.2}
            radius={516}
            align={["right", "top"]}
            position={[-114, 768]}
            fill="#F6F6F3"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={1.3}
            radius={478}
            align={["left", "bottom"]}
            position={[-93, 1584]}
            fill="#F3F3EF"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={2.5}
            radius={111}
            align={["right", "bottom"]}
            position={[-24, 1529]}
            fill="#eeeee9"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={1}
            radius={586}
            align={["right", "bottom"]}
            position={[167, notLargestScreen ? -500 : 636]}
            fill="#eeeee9"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={2.3}
            radius={222}
            align={["right", "bottom"]}
            position={[-41, 230]}
            fill="#F3F3EF"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={1.4}
            radius={417}
            align={["right", "bottom"]}
            position={[252, 21]}
            fill="#ffffff"
          />
          <FloatingCircle
            sectionRef={ref}
            depth={2.1}
            radius={183}
            align={["left", "bottom"]}
            position={[147, 69]}
            fill="#F6F6F3"
          />
        </CirclesContainer>
      ) : (
        ""
      )}
      <VerticalCardsContainer className="suradnici">
        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.matijaPrka.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Prim. Matija Prka, dr. med.</h3>
              <h4>specijalist ginekologije i opstetricije</h4>
              <p>
                Matija je ginekolog/opstetričar u KB Sveti Duh. Voli dinamičnost
                rađaone, zagovara prirodni porođaj, primjenjuje individualni
                pristup rodilji te uživa u timskom radu s primaljom i doulom. Na
                fotografiji s prof. Kyprosom Nicolaidesom, pionirom fetalne
                medicine.
              </p>
            </span>
            <Button onClick={openDrPrka}>Više o dr. matiji</Button>
          </div>
        </VerticalCard>
        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.danijelaLovincic.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Danijela Lovinčić</h3>
              <h4>certificirani hipnoterapeut</h4>
              <p>
                Danijela, certificirani hipoterapeut i osnivačica Zdrave glave,
                nudi usluge hipnoterapije i radionice samohipnoze u cilju
                postizanja željenih ciljeva te pomaganja ljudima u poboljšanju
                kvalitete njihova života.
              </p>
            </span>
            <Button
              onClick={() =>
                newTabLink("http://zdravaglava.hr/o-hipnoterapeutu/")
              }
            >
              Više o danijeli
            </Button>
          </div>
        </VerticalCard>
        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.ivaBrcic.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Iva Brčić</h3>
              <h4>certificirana edukatorica</h4>
              <p>
                Iva je osnivačica udruge Najsretnija beba i certificirana
                edukatorica. Kroz radionice i predavanja podučava polaznike
                tehnikama koje smiruju kolike u svega par minuta, poboljšavaju
                spavanje beba i djece, smiruju ispade bijesa i olakšavaju
                komunikaciju s djecom.
              </p>
            </span>
            <Button
              onClick={() =>
                newTabLink(
                  "https://najsretnijabeba.webs.com/holisticko-majcinstvo"
                )
              }
            >
              Više o ivi
            </Button>
          </div>
        </VerticalCard>
        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.sandraKrenBalen.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Sandra Kren Balen</h3>
              <h4>postpartum doula</h4>
              <p>
                Sandra je doula specijalizirana za postpartum period, odnosno
                babinje. Osim pripreme za porod, daje podršku za vrijeme poroda
                i pri dojenju, radi baby handling, masažu tijela i svakodnevne
                kućanske poslove. Dolazi u Vaš dom u vrijeme po dogovoru.
              </p>
            </span>
            <div
              style={{
                fontSize: 16,
                lineHeight: "24px",
                textAlign: "center",
                color: "rgba(24, 24, 28, 0.6)",
                width: 296,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              098 587 171
              <br />
              sandrakren@yahoo.com
            </div>
          </div>
        </VerticalCard>
        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.majaDzapo.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Maja Džapo</h3>
              <h4>edukatorica</h4>
              <p>
                Maja, diplomirana ekonomistica i majka troje djece, otvorila je
                MamaSoon, edukativni centar za trudnice i mlade mame. U njenom
                centru možete dobiti kvalitetnu i provjerenu informaciju,
                sudjelovati u vježbama disanja i istezanja, dobiti masažu kod
                fizioterapeutice i još mnogo toga.
              </p>
            </span>
            <Button onClick={() => newTabLink("https://mamasoon.hr/o-nama/")}>
              Više o maji
            </Button>
          </div>
        </VerticalCard>

        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.andreaVidas.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Andrea Vidas</h3>
              <h4>učiteljica yoge</h4>
              <p>
                Vlasnica i voditeljica Velvet Pepper Studija svoje znanje,
                ljubav i veselje vježbanja dijeli s drugima predano podučavajući
                yogu. Zaljubljenica je u terapiju masažom, posebno abdominalnom
                masažom. Njen fokus na žensko zdravlje odveo ju je u
                istraživanje terapijskog pristupa yogi, koji prožima sve njene
                satove.
              </p>
            </span>
            <Button
              onClick={() =>
                newTabLink(
                  "http://www.velvetpepperstudio.hr/ucitelji-svi/andrea-vidas/"
                )
              }
            >
              Više o andrei
            </Button>
          </div>
        </VerticalCard>
        <VerticalCard>
          <Img
            className="profile-image"
            fluid={data.doraSegovic.childImageSharp.fluid}
          />
          <div>
            <span>
              <h3>Dora Šegović</h3>
              <h4>učiteljica prenatalne yoge</h4>
              <p>
                Ova certificirana učiteljica prenatal i postnatal yoge kroz
                svoje satove čini trudnoću lakšom. Njeguje terapijski pristup.
                Njeni satovi upotpunjeni su korisnim vježbama disanja i
                meditacijama za opuštanje. Nudi usluge grupnih i privatnih sati
                uz online opciju u sklopu Velvet Pepper Studija.
              </p>
            </span>
            <Button
              onClick={() =>
                newTabLink(
                  "http://www.velvetpepperstudio.hr/ucitelji-svi/dora-segovic/"
                )
              }
            >
              Više o dori
            </Button>
          </div>
        </VerticalCard>
      </VerticalCardsContainer>
      {isDrPrkaOpen ? <DrPrka onClose={closeDrPrka} /> : ""}
    </CircleSection>
  )
}

export default Suradnici
