import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import useMedia from "use-media"
import Layout from "../components/layout"
import { TitleSection } from "../components/sections"

import SEO from "../components/seo"

import Circles1 from "../components/o-nama/Circles1"
import Circles2 from "../components/o-nama/Circles2"
import Circles3 from "../components/o-nama/Circles3"
import Circles4 from "../components/o-nama/Circles4"
import Suradnici from "../components/o-nama/Suradnici"
import useOnScreen from "../hooks/useOnScreen"

const ONamaPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      oMeni: file(relativePath: { eq: "o-nama/o-meni.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oMeniMobile: file(relativePath: { eq: "o-nama/o-meni-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      druzimoSe: file(relativePath: { eq: "o-nama/druzimo-se.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      druzimoSeMobile: file(
        relativePath: { eq: "o-nama/druzimo-se-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mojiSuradnici: file(relativePath: { eq: "o-nama/moji-suradnici.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mojiSuradniciMobile: file(
        relativePath: { eq: "o-nama/moji-suradnici-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const isMobile = useMedia({ maxWidth: 500 })
  const isLarge = useMedia({ minWidth: 1050 })

  const [subsection, setSubsection] = useState()

  const oMeniRef = useRef()
  const oMeniIntersect = useOnScreen(oMeniRef)
  const druzimoSeRef = useRef()
  const druzimoSeIntersect = useOnScreen(druzimoSeRef)
  const mojiSuradniciRef = useRef()
  const mojiSuradniciIntersect = useOnScreen(mojiSuradniciRef)

  // these are listed in reverse order going down the page
  // precedence then is given to the topmost sections
  useEffect(() => {
    if (mojiSuradniciIntersect) {
      setSubsection("Moji suradnici")
    } else if (druzimoSeIntersect) {
      setSubsection("Družimo se")
    } else if (oMeniIntersect) {
      setSubsection("O meni")
    } else {
      setSubsection()
    }
  }, [druzimoSeIntersect, mojiSuradniciIntersect, oMeniIntersect])

  // scroll areas
  useEffect(() => {
    // reach router default behaviour is to scroll to top :/
    setTimeout(() => {
      if (location.href.endsWith("o-meni")) {
        oMeniRef.current.scrollIntoView()
      } else if (location.href.endsWith("druzimo-se")) {
        druzimoSeRef.current.scrollIntoView()
      } else if (location.href.endsWith("moji-suradnici")) {
        mojiSuradniciRef.current.scrollIntoView()
      }
    }, 100)
  }, [location])

  return (
    <Layout subsection={subsection}>
      <SEO title="O Nama" />
      <div ref={oMeniRef}>
        <BackgroundImage
          Tag="section"
          fluid={
            isMobile
              ? data.oMeniMobile.childImageSharp.fluid
              : data.oMeni.childImageSharp.fluid
          }
          backgroundColor="#c4c4c4"
        >
          <TitleSection img>
            <div>
              <h2>O meni</h2>
              <p style={useMedia({ maxWidth: 650 }) ? {} : { width: 430 }}>
                Vaša tiha pratnja kroz trudnoću,
                <br />
                na porodu i u danima babinja.
              </p>
            </div>
          </TitleSection>
        </BackgroundImage>
        <Circles1 isIntersecting={oMeniIntersect} />
        <Circles2 isIntersecting={oMeniIntersect} />
      </div>
      <div ref={druzimoSeRef}>
        <BackgroundImage
          Tag="section"
          fluid={
            isMobile
              ? data.druzimoSeMobile.childImageSharp.fluid
              : data.druzimoSe.childImageSharp.fluid
          }
          backgroundColor="#c4c4c4"
        >
          <TitleSection img>
            <div style={{ transform: "translateY(1rem)" }}>
              <h2>Družimo se</h2>
              <p style={{}}>
                U mojoj Maloj kući, na čarobnom mjestu u prirodi, u mom
                opuštajućem prostoru ili u Vašem domu
              </p>
            </div>
          </TitleSection>
        </BackgroundImage>
        <Circles3 isLarge={isLarge} isIntersecting={druzimoSeIntersect} />
        <Circles4 isLarge={isLarge} isIntersecting={druzimoSeIntersect} />
      </div>
      <div ref={mojiSuradniciRef}>
        <BackgroundImage
          Tag="section"
          fluid={
            isMobile
              ? data.mojiSuradniciMobile.childImageSharp.fluid
              : data.mojiSuradnici.childImageSharp.fluid
          }
          backgroundColor="#c4c4c4"
        >
          <TitleSection img>
            <div>
              <h2 style={{ marginBottom: 100 }}>Moji suradnici</h2>
            </div>
          </TitleSection>
        </BackgroundImage>
        <Suradnici isIntersecting={mojiSuradniciIntersect} />
      </div>
    </Layout>
  )
}

export default ONamaPage
