import React, { useRef } from 'react'
import useMedia from 'use-media'
import { CircleSection } from '../sections'
import FloatingCircle, { CirclesContainer } from '../FloatingCircle'

const Circles = ({ isIntersecting }) => {
  const ref = useRef()
  const isMobile = useMedia({ maxWidth: 650 })

  return (
    <CircleSection bg="pinklightest" ref={ref}>
      <CirclesContainer>
        {isMobile || !isIntersecting ? (
          ''
        ) : (
          <FloatingCircle
            sectionRef={ref}
            depth={2}
            radius={188}
            align={['left', 'top']}
            position={[3, 64]}
            fill="#FCE4EC"
          />
        )}

        <FloatingCircle
          sectionRef={ref}
          depth={1}
          radius={280}
          align={['right', 'top']}
          position={[-63, 64]}
          fill="#FCEBF1"
        />
        <FloatingCircle
          sectionRef={ref}
          depth={2.5}
          radius={84}
          align={['left', 'top']}
          position={[-19, 276]}
          fill="#FDF2F5"
        />

        <FloatingCircle
          sectionRef={ref}
          depth={3}
          radius={188}
          align={['left', 'bottom']}
          position={[23, 140]}
          fill="#ffffff"
        />
      </CirclesContainer>
      <p>
        <b>Majka sam trojice sinova</b>, kći, supruga, prijateljica i doula.
        Završila sam Fakultet kriminalističkih znanosti i opredijelila se za
        borbu za prava žena i djece u obitelji. U svojim tridesetima{' '}
        <b>zaljubila sam se u zanimanje doule</b> koje me do danas ispunjava i
        usrećuje.
      </p>
    </CircleSection>
  )
}

export default Circles
